import React, { useContext } from 'react'
import { graphql, Link } from 'gatsby'
import { AdStarBlock, Category, GeneralSettings } from '../../types'
import { IMenu } from '../../graphql/entities/Menu'
import TopBanner from '../../components/Banners/TopBanner/TopBanner'
import Content from '../../components/Content/Content'
import Footer from '../../components/Footer/Footer'
import Header from '../../components/Header/Header'
import AwardsHeader from '../../components/MuddyAwards/AwardsHeader'
import AwardsMenu from '../../components/MuddyAwards/AwardsMenu'
import Breadcrumb from '../../components/organisms/breadcrumb/Breadcrumb'
import PageMeta from '../../components/PageMeta/PageMeta'
import { AWARDS_COUNTY_INFO } from '../../lib/data/Awards'
import Leaderboard from '../../components/Banners/Leaderboard/Leaderboard'
import { AwardCat } from './live-results'
import { MuddyAwardsContext, MuddyAwardsStates } from '../../lib/utils'
import * as LeaderStyles from '../../components/Leader/Leader.module.scss'

export const query = graphql`
  query {
    wpgraphql {
      generalSettings {
        title
        siteId
        egSettingTwitter
        url
      }
      awardsCategories {
        title
        image
        description
        id
        finalists {
          meta_a
          meta_b
          meta_c
          meta_d
          meta_e
        }
      }
      adStarCodes {
        id
        adslot
      }
      awardsMenu: menu(id: "Awards", idType: NAME) {
        menuItems {
          nodes {
            id
            path
            label
          }
        }
      }
      category(id: "muddy-awards", idType: SLUG) {
        sponsoredBy
        sponsoredLogo
      }
    }
  }
`

interface AwardsFAQProps {
  data: {
    wpgraphql: {
      generalSettings: GeneralSettings
      adStarCodes: AdStarBlock[]
      awardsMenu: {
        menuItems: {
          nodes: IMenu[]
        }
      }
      awardsCategories: AwardCat[]
      category: Category
    }
  }
}

const AwardsFinalists = ({
  data: {
    wpgraphql: {
      generalSettings,
      adStarCodes,
      awardsCategories,
      awardsMenu: {
        menuItems: { nodes: subMenu }
      },
      category
    }
  }
}: AwardsFAQProps) => {
  const AwardsContext = useContext(MuddyAwardsContext)
  const countyInfo = generalSettings.siteId
    ? AWARDS_COUNTY_INFO.filter(c => c.siteId === generalSettings.siteId)[0]
    : AWARDS_COUNTY_INFO[0]

  // if (generalSettings.siteId === 1) return null

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: 'Muddy Stilettos Awards 2024',
          description:
            'The Muddy Stilettos Awards are the most coveted awards for independent lifestyle businesses across 28 counties, and totally FREE to enter!',
          image:
            'https://muddystilettos.co.uk/images/awards/Awards_Badge_2024.gif'
        }}
      />
      <Header />
      <TopBanner ads={adStarCodes} pageType={`awards`} />
      <Leaderboard adSlot={'TopLB'} />
      {category && (
        <AwardsHeader category={category} generalSettings={generalSettings} />
      )}
      {subMenu && (
        <AwardsMenu subMenu={subMenu} generalSettings={generalSettings} />
      )}
      <div>
        <Content>
          <Breadcrumb
            postType={`awards`}
            postTitle={'/ About Us'}
            postURI={'/awards/about-us'}
          />
          <h1
            dangerouslySetInnerHTML={{
              __html: 'Meet your Muddy Awards 2024 Finalists!'
            }}
          />
          <p>
            You’ve voted, we’ve counted (and counted, and counted) and the{` `}
            {countyInfo?.county ?? 'County'} Muddy Awards Regional Finals are
            now officially OPEN!{' '}
          </p>
          <p>
            THANK YOU for taking the time to support your awesome local
            businesses - the response, as always, has been incredible. We wish
            every brilliant business nominated could make it through, but there
            can only be five Finalists in each category, and now the Muddy
            readers will vote for who should win the coveted{' '}
            {countyInfo?.county ?? 'County'} Award.
          </p>
          <p>
            As an added bonus, all Regional winners across all 28 Muddy counties
            will be put forward for the ‘Best of the Best’ National Muddy Awards
            – even more reason to show your support this year!{' '}
          </p>
          <p>
            {AwardsContext === MuddyAwardsStates.VOTING ? (
              <>
                The Regional Awards vote closes on 18 April 2024 at 1pm, so if
                there’s a business you love, make sure you{' '}
                <Link to={`/awards/vote/`}>VOTE HERE</Link>.
              </>
            ) : (
              <>
                The Regional Awards vote are now closed. Watch this space!
                Regional Winners will be announced 2nd May 2024.
              </>
            )}
          </p>
          <p>
            Want to see who made it through to the{' '}
            {countyInfo?.county ?? 'County'} Finals? Course you do! Say hello
            to:{' '}
          </p>

          {AwardsContext !== MuddyAwardsStates.VOTING && (
            <>
              {awardsCategories.map((cat, i) => (
                <div key={i} style={{ marginBottom: '3em' }}>
                  <h2
                    style={{
                      textTransform: 'uppercase',
                      color: 'rgb(231, 44, 94)'
                    }}
                    dangerouslySetInnerHTML={{
                      __html: `${cat.title
                        .replace('Best ', '')
                        .replace(' / ', '/')
                        .replace('/', ' / ')}${i === 0 ? ' Venue' : ''}`
                    }}
                  />
                  {cat.finalists &&
                    Object.values(cat.finalists).map((finalist, j) => {
                      //   const finalistRaw = cat.finalists?[finalist] ? finalist : `${finalist} (Town)`
                      //   const finalistRaw = 'Business Name (Town)'
                      const finalistRaw =
                        finalist.length > 0
                          ? finalist
                          : `Business ${j + 1} Name (Town)`
                      const businessName = finalistRaw.split(' (')[0]
                      const town = finalistRaw.split(' (')[1]?.replace(')', '')

                      return (
                        <p key={j}>
                          <strong>{businessName}</strong> ({town})
                        </p>
                      )
                    })}
                </div>
              ))}
            </>
          )}
          {AwardsContext === MuddyAwardsStates.VOTING && (
            <p>
              <Link
                className={LeaderStyles.Button}
                style={{ width: 'fit-content' }}
                to={
                  generalSettings.siteId === 1
                    ? `/awards/counties`
                    : `/awards/categories`
                }
              >
                Click here to start voting
              </Link>
            </p>
          )}
        </Content>
      </div>
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default AwardsFinalists
